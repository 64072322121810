<template>
  <div class="wrapper">
    <Menu></Menu>

    <Slider></Slider>

    <Boosted></Boosted>

    <div class="body mt-1">
      <Highlights></Highlights>

      <div class="sport_dropdowns">

        <!-- Sports dropdown -->
        <div class="btn-group">

          <span
              class="dropdown-filter dropdown-toggle"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              v-text="sport_name">
          </span>

          <div class="dropdown-menu">

        <span
            v-for="s in sports"
            v-bind:key="getKey(s.sport_id)"
            class="dropdown-item sport_dropdown_item black-txt"
            @click="setSport(s)"
            v-text="s.sport_name">
        </span>
          </div>
        </div>

        <div class="btn-group">
          <span
              class="dropdown-filter"
              v-bind:class="geChildPageActiveClass(-1)"
              @click="setHourAll">
            All
          </span>
        </div>

        <!-- Top Leagues dropdown -->
        <div class="btn-group" v-for="(d,index) in hours" v-bind:key="index">
          <span
              class="dropdown-filter"
              v-bind:class="geChildPageActiveClass(d)"
              @click="setHour(d)">
            {{ getHourName(d) }}
          </span>
        </div>

      </div>
      <Games v-bind:sport="sport_id" v-bind:date="date" upcoming="0" today="true"></Games>
      <Trending class=""></Trending>
      <BottomFooter></BottomFooter>
      <bottom-navigation active-bets="0" page="today"></bottom-navigation>
    </div>

  </div>
</template>

<script>
import Highlights from './Highlights'
import Slider from './Slider'
import Games from './Games'
import BottomFooter from './BottomFooter'
import Menu from './Menu'
import Boosted from './Boosted'
import BottomNavigation from './BottomNavigation'
import Trending from './Trending'

export default {
  name: 'Home',
  components: {
    Menu,
    Slider,
    Highlights,
    Games,
    BottomFooter,
    BottomNavigation,
    Trending,
    Boosted
  },
  data: function (){
    return {
      sub_page: '',
      date: this.getToday(),
      selected_hour: 'All',
      hours: [1,3,6],
      sport: {},
      sport_name: 'Sports',
    }
  },
  mounted() {

    this.$store.dispatch("setCurrentPage","today");
    this.autoRefreshUI(this.$vnode.tag);
    this.reloadProfile();

  },
  computed: {
    sports: function() {
      return this.$store.state.sports
    },

    betslip_count: function (){

      return this.$store.state.betslip.total

    },
    current_sub_page: function () {

      return this.$store.state.current_sub_page;
    },
    sport_id: function (){

      return this.$store.state.sport_id
    },
    market_id: function () {

      return this.$store.state.market_id;
    },
    hour: function () {

      return this.$store.state.hour;

    },
  },
  methods: {
    getKey: function(index) {

      return Math.random().toString(20).replace('0.', 'random-id-'+index + '-');

    },
    setSport: function(sport){

      this.sport = sport;
      this.sport_name = sport.sport_name;
      this.$store.dispatch("setSportID",sport.sport_id);
      this.$store.dispatch("setSportName",sport.sport_name);

    },

    setSubPage: function (page){

      this.$store.dispatch("setCurrentSubPage",page);
      this.sub_page = page;

    },
    geSubPageActiveClass: function (subpage) {

      return subpage === this.sub_page ? 'active' : "";

    },
    geChildPageActiveClass: function (childpage) {

      return parseInt(childpage) === parseInt(this.$store.state.hour) ? 'selected-date' : "";

    },
    setHour: function(h){

      this.selected_hour = h;
      this.$store.dispatch("setHour",h);

    },
    setHourAll: function (){

      this.$store.dispatch("setHour",-1)

    },
    getHourName: function(i){

      if(parseInt(i) ===-1 ) {

        return "All"
      }

      return parseInt(i) + " Hrs";

    },
  }
}
</script>