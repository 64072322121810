<template>
  <div class="wrapper">
    <section class="carousel-section ">
      <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">

        <div class="carousel-inner">
          
          <!-- <div class="carousel-item active">
            <router-link to="/match/prematch/50677/Manchester-United-vs-Fulham-FC">
              <img
                style="object-fit:contain;border-radius: 7px;width: 98%;margin-left: 1%;margin-right: 1%;margin-top: 5px;"
                class="d-block" src="/img/carousel/epl2.jpg">
            </router-link>
          </div> -->
          
          <div class="carousel-item active">
            <router-link to="/freebet">
              <img
                style="object-fit:contain;border-radius: 7px;width: 98%;margin-left: 1%;margin-right: 1%;margin-top: 5px;"
                class="d-block" src="/img/carousel/freebet.png">
            </router-link>
          </div>

          <!-- <div class="carousel-item">
            <router-link to="/deposit">
              <img
                style="object-fit:contain;border-radius: 7px;width: 98%;margin-left: 1%;margin-right: 1%;margin-top: 5px;"
                class="d-block" src="/img/carousel/deposit_bonus.png">
            </router-link>
          </div> -->

          <!-- <div class="carousel-item">
          <a @click="setSportMenu(1, 'Soccer')">
            <img
              style="object-fit:contain;border-radius: 7px;width: 98%;margin-left: 1%;margin-right: 1%;margin-top: 5px;"
              class="d-block" src="/img/carousel/slider_3.jpeg">
          </a>
        </div> -->

          <div class="carousel-item">
            <router-link
              :to="{ name: aviatorCrashGameUrl, params: { IsDemo: 0, providerID: aviatorProviderID, gameName: aviatorGameName, gameID: aviatorGameID } }">
              <img
                style="object-fit:contain;border-radius: 7px;width: 98%;margin-left: 1%;margin-right: 1%;margin-top: 5px;"
                class="d-block" src="/img/carousel/aviator.png">
            </router-link>
          </div>

          <div class="carousel-item">
            <router-link 
            :to="{ name: jetxCrashGameUrl, params: { IsDemo: 0, providerID: jetxProviderID, gameName: jetxGameName, gameID: jetxGameID } }">
              <img
                style="object-fit:contain;border-radius: 7px;width: 98%;margin-left: 1%;margin-right: 1%;margin-top: 5px;"
                class="d-block" src="/img/carousel/jetx.png">
            </router-link>
          </div>
        </div>
        <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="sr-only">Previous</span>
        </a>
        <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="sr-only">Next</span>
        </a>
      </div>
    </section>
  </div>
</template>
<script>
export default {
  name: "Slider",
  data: function () {
    return {
      aviatorProviderID: 7,
      aviatorGameID: "aviator",
      aviatorGameName: "Aviator",
      aviatorCrashGameUrl: "aviator",
      //
      jetxProviderID: 5,
      jetxGameID: "JetX",
      jetxGameName: "Jet X",
      jetxCrashGameUrl: "jetx",
    }
  },
  methods: {
    setSportMenu: function (sport_id, sport_name) {
      this.sport_name = sport_name;
      this.$store.dispatch("setSportID", sport_id);
      this.$store.dispatch("setCurrentPage", sport_name);
      console.log(this.$store.state.sport_id)
      this.$router.push({ name: 'sport', params: {} });
    },
  },
}
</script>